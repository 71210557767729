import React from 'react';
import { Card, Row, Col } from 'antd';
import {
  FileZipOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  FileMarkdownOutlined,
  FileTextOutlined,
  FilePptOutlined,
  FileExcelOutlined,
  FileUnknownOutlined,
  EyeTwoTone,
  DeleteTwoTone,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../store/actions/modalActions';
import { fileTypes } from '../../../utils/constants';
import { message } from 'antd';
const getFileIcon = (fileType) => {
  switch (fileType) {
    case 'zip':
      return <FileZipOutlined style={{ color: '#ff8c00' }} />;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'doc':
    case 'docx':
      return <FileWordOutlined style={{ color: '#1e90ff' }} />;
    case 'application/pdf':
      return <FilePdfOutlined style={{ color: '#ff4d4f' }} />;
    case 'image/jpg':
      return <FileImageOutlined style={{ color: '#1e90ff' }} />;

    case 'image/jpeg':
    case 'jpg':
      return <FileImageOutlined style={{ color: '#1e90ff' }} />;

    case 'image/png':
      return <FileImageOutlined style={{ color: '#1e90ff' }} />;
    case 'gif':
    case 'psd':
      return <FileImageOutlined style={{ color: '#1e90ff' }} />;
    case 'md':
      return <FileMarkdownOutlined style={{ color: '#6d7077' }} />;
    case 'txt':
    case 'eml':
      return <FileTextOutlined style={{ color: '#8c8c8c' }} />;
    case 'ppt':
    case 'pptx':
      return <FilePptOutlined style={{ color: '#ff7a45' }} />;
    case 'xls':
    case 'xlsx':
      return <FileExcelOutlined style={{ color: '#52c41a' }} />;
    case 'mp3':
    case 'aep':
      return <FileUnknownOutlined style={{ color: '#800080' }} />;
    case 'sketch':
    case 'ai':
      return <FileUnknownOutlined style={{ color: '#ffd700' }} />;
    default:
      return <FileUnknownOutlined style={{ color: '#d9d9d9' }} />;
  }
};
const AttachmentList = ({ attachments, actionAttachment }) => {
  const dispatch = useDispatch();

  const handlePreview = ({ s3_url, file_type }) => {
    const files = ['xlsx'];
    if (files.includes(fileTypes[file_type])) {
      message.error('File not supported');
      return;
    }

    dispatch(
      showModal({
        type: 'SHOW_MODAL',
        modalType: 'ATTACHMENT_PREVIEW',
        modalProps: {
          show: true,
          fileUrl: s3_url,
          fileType: fileTypes[file_type],
        },
      })
    );
  };
  return (
    <div>
      <Row gutter={[8, 8]} justify="start">
        {attachments.map((attachment, index) => (
          <Col key={index} span={24} sm={12} md={8} lg={6}>
            <Card
              hoverable
              bodyStyle={{
                padding: '8px 2px ',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              style={{ borderRadius: '8px', minHeight: '50px' }}
              onClick={() => handlePreview(attachment)}
            >
              <Row align="middle" gutter={[8, 0]}>
                <Col span={6} style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '30px' }}>
                    {getFileIcon(attachment.file_type)}
                  </div>
                </Col>
                <Col span={12}>
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: '13px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {attachment.file_name}
                  </div>
                  <div
                    style={{
                      fontSize: '10px',
                      color: 'grey',
                      marginTop: '5px',
                    }}
                  >
                    {attachment.file_size}
                  </div>
                </Col>
                <Col span={6} style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '20px' }}>
                    <DeleteTwoTone
                      onClick={() =>
                        actionAttachment({
                          ...attachment,
                          action_type: 'delete',
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default AttachmentList;
