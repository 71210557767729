import axios from 'axios';

function setupAuthorizationInterceptorLocal(instance) {
  instance.interceptors.request.use(function (config) {
    const token = localStorage.access_token || sessionStorage.access_token;
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });
}

export const https = axios.create({
  baseURL: process.env.REACT_APP_ICONTROL_URL,
});
// cmm https
export const cmmHttps = axios.create({
  baseURL: process.env.REACT_APP_ICONTROL_URL,
});

// ecm https
export const ecmHttps = axios.create({
  baseURL: process.env.REACT_APP_ECM_URL,
});

export const mtfHttps = axios.create({
  baseURL: process.env.REACT_APP_MTF_URL,
});

export const dncHttps = axios.create({
  baseURL: process.env.REACT_APP_DNC_URL,
});
export const ncoHttps = axios.create({
  baseURL: process.env.REACT_APP_NCO_URL,
  // baseURL: 'https://icontrol-backend.icomplytech.com/ncobackendtest/',
  
});


// nco test https
export const ncotestHttps = axios.create({
  // baseURL: process.env.REACT_APP_NCO_URL,
  baseURL: 'https://icontrol-backend.icomplytech.com/ncobackendtest/',

});


export const rmHttps = axios.create({
  baseURL: process.env.REACT_APP_RM_URL,
});

export const fetch = axios.create({
  baseURL: process.env.REACT_APP_IACCESS_URL,
});
export const iaccessHttps2 = axios.create({
  baseURL: process.env.REACT_APP_IACCESS_URL,
});
export const idocHttps = axios.create({
  baseURL: process.env.REACT_APP_IDOC_URL,
});
export const ammHttps = axios.create({
  baseURL: process.env.REACT_APP_AMM_URL,
});

export const vmmHttps = axios.create({
  baseURL: 'https://icontrol.icomplytech.com:8080',
});
export const iaccessHttps = axios.create({
  baseURL: process.env.REACT_APP_IACCESS_URL,
});

setupAuthorizationInterceptorLocal(ammHttps);
setupAuthorizationInterceptorLocal(vmmHttps);
setupAuthorizationInterceptorLocal(idocHttps);
setupAuthorizationInterceptorLocal(ncoHttps);
setupAuthorizationInterceptorLocal(iaccessHttps);
setupAuthorizationInterceptorLocal(fetch);


setupAuthorizationInterceptorLocal(ncotestHttps);