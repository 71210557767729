import axios from 'axios';
import { idocHttps } from '../../../utils/config';

export const CCM_CONFIG = 'CCM_CONFIG';

export const CCMConfig = () => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    const data = {
      type: 'global_config',
    };
    axios
      .post(`${process.env.REACT_APP_IACCESS_URL}/globalConfig/`, data, config)
      .then(({ data }) => {
        dispatch({ type: CCM_CONFIG, data });
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const fetchCCiDocDocuments = (formBody) => (dispatch) => {
  idocHttps
    .post('/fetchiDocDocuments/', formBody)
    .then(({ data }) => {
      dispatch({
        type: 'OTHER_DOCUMENTS',
        data: data?.map((item) => {
          return {
            application: 'iDoc',
            module: 'Document Management Module',
            ...item,
          };
        }),
      });
    })
    .catch((err) => console.log(err));
};

export const CCMidocDocumentsDetails = (formBody) => (dispatch) => {
  const { docId, version, docuid } = formBody;
  idocHttps
    .get(
        `/viewdocument?docuid=${docuid}&version=${version}&document_no=${docId}`
    )
    .then(({ data }) => {
      dispatch({
        type: 'DOCUMENTS_DETAILS',
        data: data,
      });
    })
    .catch((err) => console.log(err));
};
