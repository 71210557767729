import { NCOAudit } from '../../../utils/helper';
import { ncoHttps } from '../../../utils/config';
import { message } from 'antd';
import { sendEmail } from '../commonActions';
import { hideModal } from '../modalActions';
import { getCAPAImplementationList } from './CAPAImplementationActions';
import moment from 'moment';
export const GET_INITIATION_DATA = 'GET_INITIATION_DATA';
export const GET_DRAFT_DATA = 'GET_DRAFT_DATA';

export const ncoInitiate =
  (formBody, history, role, flow, attachment_role) => async (dispatch) => {
    const obj = JSON.stringify(formBody);
    try {
      await ncoHttps.post('/initiate.php', obj, role, flow, attachment_role);
      message.success({
        content: 'Form Submission Successful',
        key: 'updatable',
      });
      history.push('/nco/initiation');
      try {
        await audit({ ...formBody, stage: 'CAPA Plan Initiation' });
      } catch (err) {
        message.error('Error Submitting! Try again.');
        return;
      }
    } catch (err) {
      message.error('Error Submitting! Try again.');
    }
  };

export const audit = async (formBody) => {
  const type = formBody.type;
  const remove = ['attachments'];
  remove.forEach((_) => {
    delete formBody[_];
  });
  await ncoHttps.post('/Audit.php', formBody, type);
  return;
};

export const cancelCAPAPlan =
  (formBody, history, route) => async (dispatch) => {
    const mailObj = {
      email_type:
        formBody.action_type == 'cancelled'
          ? 'capa_plan_cancel'
          : 'capa_cancel_assignment',
      action_by: localStorage.getItem('username'),
      nco_no: formBody.nco_no,
      stage:
        localStorage.getItem('role') == 'qa_reviewer'
          ? 'CAPA Plan Verification'
          : 'CAPA Plan Approval',
    };

    try {
      await ncoHttps.post('/cancelCAPAplan.php', formBody);
      message.success({
        content: 'Form Submission Successful',
        key: 'updatable',
      });
      sendEmail(mailObj);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      history.push(`/nco/${route}`);
    } catch (err) {
      message.error('Error Submitting! Try again.');
    }
  };

export const getNCOAttachmentsList =
  (nco_no, formData, attachment_role, flow) => (dispatch) => {
    ncoHttps
      .post(`/getAttachments.php?nco_no=${nco_no}`)
      .then(({ data }) => {
        dispatch({
          type: 'GET_ATTACHMENTS_LIST',
          data:data?.['nco_attachment']
          .concat(data?.['rca_attachment'])
          .concat(data?.['ca_attachment'])
          .concat(data?.['check_attachments'])
          .reverse(),
          formData,
          attachment_role,
          flow,
        });
      })

      .catch((err) => console.log(err));
    return;
  };

export const getActionItemTable = (type) => (dispatch) => {
  // const module = localStorage.getItem('module');
  const module = localStorage.getItem('module');
  const username = localStorage.getItem('username');
  const name = localStorage.getItem('name');
  const organization = localStorage.getItem('organization');

  const obj = JSON.stringify({
    organization: organization,
    responsible_person: `${name} (${username})`,
  });

  ncoHttps
    .post(
      `/actionItemTable.php?request_type=${
        type ? 'my_request' : 'all_request'
      }`,
      obj
    )
    .then(({ data }) => {
      const res = Array.isArray(data) ? data : [];
      const sortedItems = [...res]?.sort((a, b) =>
        moment(b.notification_date).diff(moment(a.notification_date))
      );
      dispatch({
        type: 'GET_FUNCTIONAL_REVIEW_LIST',
        data: sortedItems,
        total: res.length,
      });
    })
    .catch(({ response }) => {
      dispatch({
        type: 'GET_FUNCTIONAL_REVIEW_LIST',
        data: [],
        total: 0,
      });
    });
};

export const changeActionOwner = (formData) => (dispatch) => {
  ncoHttps
    .post('/changeActionOwner.php', formData)
    .then((_) => {
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'ACTION_OWNER_CHANGE',
          modalProps: { show: false },
        })
      );
      if (formData.type == 'action_item') {
        dispatch(getActionItemTable());
      } else if (formData.type == 'action_item') {
        dispatch(getCAPAImplementationList());
      }
    })
    .catch((err) => console.log(err));
  return;
};
